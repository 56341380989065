<template>
  <div>
    <b-row class="my-2" v-if="ready">
      <b-col >
        <TicketList :tickets="tickets"/>
      </b-col>
    </b-row>
    <div class="container" v-else>
      <AppPulsingIcon />
    </div>
  </div>
</template>

<script>
import TicketList from "../../components/tickets/TicketList";
import {mapState} from "vuex";
import AppPulsingIcon from "../../components/AppPulsingIcon";

export default {
  components: {
    AppPulsingIcon,
    TicketList,
  },
  data() {
    return {
      ready: false,
    };
  },

  computed: {
    ...mapState({
      tickets: (state) => state.tickets.all,
    }),
  },

  async created(){
    try {
      await this.$store.dispatch("tickets/fetchTickets");
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title: "Une erreur est survenue lors de la récupération des tickets",
        text: e.message,
      });
    }
    this.ready = true
  },



}
</script>

<style scoped>
  .container{
    margin-top: 3em;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

