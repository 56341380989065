<template>
  <b-button @click="$emit('refuse')" variant="outline-danger">
    <font-awesome-icon icon="times"/>
  </b-button>
</template>


<script>
export default {
  name: "RefuseButton"
}
</script>

<style scoped>

</style>
