<template>
  <b-button-group>
    <AcceptButton class="no-border" @accept="$emit('accept')"/>
    <RefuseButton
        class="no-border"
        @refuse="$emit('refuse')"
    />
  </b-button-group>
</template>

<script>

import AcceptButton from "./AcceptButton";
import RefuseButton from "./RefuseButton";

export default {
  components: {
    AcceptButton,
    RefuseButton,
  },
}
</script>

<style scoped>

</style>
