<template>
  <b-button @click="$emit('accept')" variant="outline-success">
    <font-awesome-icon icon="check"/>
  </b-button>
</template>
<script>
export default {
  name: "AcceptButton"
}
</script>

<style scoped>

</style>
