<template>
  <div>

    <div v-if="tickets.length > 0">
      <b-card
          v-for="ticket in tickets"
          v-bind:key="ticket.uid"
          class="text-center mt-5"
          :header="ticket.domain"
          header-tag="header"
          :title="ticket.user"
      >

        <b-card-text>
          {{ticket.comments}}
        </b-card-text>

        <AcceptRefuseButton
            @accept="AcceptTicket(ticket.uid)"
            @refuse="RefuseTicket(ticket.uid)"
        />
      </b-card>
    </div>


    <div class="text-center mt-5" v-else>
      <span class="text-info">
        Aucune demande n'est en cours.
      </span>
    </div>

  </div>
</template>

<script>
import AcceptRefuseButton from "@/components/buttons/AcceptRefuseButton";

export default {
  props: {
    tickets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      empty: false,
    };
  },
  methods: {
    async AcceptTicket(ticketUid){
      try {
        await this.$store.dispatch("tickets/acceptTicket",ticketUid);
        await this.$swal.fire({
          icon: "success",
          title: "Ticket accepté",
        });
        this.empty = true
        await this.$store.dispatch("tickets/refreshList",ticketUid)
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de l'acceptation du ticket",
          text: e.message,
        });
      }
    },
    async RefuseTicket(ticketUid){
      try {
        await this.$store.dispatch("tickets/refuseTicket",ticketUid)
        await this.$swal.fire({
          icon: "success",
          title: "Ticket refusé",
        });
        this.empty = true
        await this.$store.dispatch("tickets/refreshList",ticketUid)
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors du refus du ticket",
          text: e.message,
        });
      }
    },
  },
  components: {
    AcceptRefuseButton,
  },
}

</script>

<style scoped>

</style>
